<template>
  <section>
    <b-container fluid>
      <b-col cols="12">
        <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            shape="square"
            finish-button-text="Kaydet"
            back-button-text="Geri"
            next-button-text="İleri"
            class="mb-3"
            @on-complete="formSubmitted"
        >
          <tab-content
              title="İhale Bilgileri"
              :before-change="wizardFirstRules"
              icon="feather icon-file-text"
          >
            <validation-observer
                ref="firstStep"
                tag="form"
            >
              <b-row>
                <b-col
                    cols="12"
                    class="mb-2"
                >
                  <h5 class="mb-0">
                    İhale Bilgileri
                  </h5>
                  <small class="text-muted">
                    Komisyon Üyeleri Seçilmelidir
                  </small>
                </b-col>
                <b-col md="8">
                  <b-form-group
                      label="İhale Başlığı"
                      label-for="name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="İhale Başlığı"
                        rules="required"
                    >
                      <b-form-input
                          id="name"
                          v-model="ihaleBaslik"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] ? 'İhale Başlığı Zorunludur' : '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <validation-provider
                      #default="{ errors }"
                      name="yetkili"
                      rules="required"
                  >
                    <b-form-group
                        label="İhale Yetkilisi"
                        label-for="yetkili"
                        :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                          id="yetkili"
                          v-model="yetkili"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="getKomisyonUyeleri"
                          :selectable="option => ! option.uyeID.includes('select_value')"
                          :state="errors.length > 0 ? false:null"
                          label="adSoyad"
                      >
                        <template #list-header>
                          <li
                              v-b-modal.komisyon-kayit
                              class="add-new-client-header d-flex align-items-center my-50"
                              @click="komisyonUyeTur = 'yetkili'"
                          >
                            <feather-icon
                                icon="PlusIcon"
                                size="16"
                            />
                            <span class="align-middle ml-25">Üye Ekle</span>
                          </li>
                        </template>
                        <template #no-options>
                          <span>Seçenek Yok</span>
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        İhale Yetkilisi Seçilmelidir
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <b-form-group
                      label="Belge Tarih Sayı"
                      label-for="name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Belge Tarih Sayı"
                        rules="required"
                    >
                      <cleave
                          id="delimiter"
                          class="form-control"
                          v-model="belgeTarihSayi"
                          :raw="false"
                          :options="delimiter"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                      <!--                      <b-form-input-->
                      <!--                        id="name"-->
                      <!--                        v-model="belgeTarihSayi"-->
                      <!--                        :state="errors.length > 0 ? false:null"-->
                      <!--                        autocomplete="off"-->
                      <!--                      />-->
                      <small class="text-danger">{{ errors[0] ? 'Belge Tarih Sayı Zorunludur' : '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                      label="Belge Tarih"
                      label-for="name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Belge Tarih"
                        rules="required"
                    >
                      <flat-pickr
                          v-model="belgeTarih"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] ? 'Belge Tarih Zorunludur' : '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="Alım Yapacak Birim"
                      label-for="name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Alım Yapacak Birim"
                        rules="required"
                    >
                      <b-form-input
                          id="name"
                          v-model="ilgiliBirim"
                          autocomplete="off"
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] ? 'Alım yapacak birim zorunludur.' : '' }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                      label="Proje No"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="projeNo"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="İhale Usulü"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="ihaleUsulu"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr/>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="İş Tanımı"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="isTanim"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="İş Niteliği"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="isNitelik"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="İş Miktarı"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="isMiktar"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <!--                <b-col md="3">-->
                <!--                  <b-form-group-->
                <!--                    label="Ödenecek Tutar"-->
                <!--                    label-for="name"-->
                <!--                  >-->
                <!--                    <b-form-input-->
                <!--                      id="name"-->
                <!--                      v-model="odenekTutari"-->
                <!--                      autocomplete="off"-->
                <!--                      :step="priceStep"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <b-col md="3">
                  <b-form-group
                      label="Bütçe Tertibi"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="butceTertibi"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="İlan Şekli Adeti"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="ilanSekliAdeti"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="Fiyat Fark Dayanağı"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="fiyatFarkDayanagi"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="Bakanlar Kurulu Kararı"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="bakanlarKuruluKarari"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="Döküman Satış Bedeli"
                      label-for="name"
                  >
                    <b-form-input
                        id="name"
                        v-model="dokumanSatisBedeli"
                        autocomplete="off"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Avans Şartları"
                      label-for="name"
                  >
                    <b-form-textarea v-model="avansSartlari"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Diğer Açıklamalar"
                      label-for="name"
                  >
                    <b-form-textarea v-model="digerAciklamalar"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
              title="Komisyon Üyeleri"
              :before-change="wizardSecondRules"
              icon="feather icon-users"
          >
            <validation-observer
                ref="secondStep"
                tag="form"
            >
              <b-row class="ihale-wizard-komisyon-card">
                <b-col
                    cols="12"
                >
                  <h5 class="mb-0">
                    Her evrak için en az 1 tane üye seçilmelidir.
                  </h5>
                  <small class="text-muted">
                    Bu bilgiler daha sonra değiştirilemez.
                  </small>
                  <hr/>
                </b-col>
                <b-col
                    cols="12"
                >
                  <b-row>
                    <b-col lg="1" md="2" sm="2" cols="12">
                      <b-form-group
                          label="Sıra No"
                          label-for="basicInput"
                      >
                        <b-form-input
                            id="basicInput"
                            autocomplete="off"
                            v-model="uyeEkle.siraNo"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="3" md="5" sm="10" cols="12">
                      <b-form-group
                          label="Üye Ekle"
                          label-for="country"
                      >
                        <v-select
                            v-model="uyeEkle.uye"
                            label="adSoyad"
                            :options="getKomisyonUyeleri"
                            no-options-text="Seçenek Yok"
                        >
                          <template #list-header>
                            <li
                                v-b-modal.komisyon-kayit
                                class="add-new-client-header d-flex align-items-center my-50"
                                @click="komisyonUyeTur = 'sirfUye'"
                            >
                              <feather-icon
                                  icon="PlusIcon"
                                  size="16"
                              />
                              <span class="align-middle ml-25">Üye Ekle</span>
                            </li>
                          </template>
                          <template #no-options>
                            <span>Seçenek Yok</span>
                          </template>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="3" sm="6" cols="6">
                      <b-form-group
                          label="Görev"
                          label-for="gorev"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-select :options="gorevOpt" v-model="uyeEkle.gorev"/>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col lg="2" md="2" sm="6" cols="6">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="komisyon-add-btn"
                          @click="komisyonUyeEkle"
                      >
                        Ekle
                      </b-button>
                    </b-col>
                    <b-col lg="4" md="12" sm="12" cols="12">
                      <div class="justify-content-end d-flex">
                        <font-awesome-icon
                            v-b-tooltip="'Bir sonraki ihale oluşturmada strandart olarak kaydedilen komisyon şeması gösterilecek'"
                            icon="fa-solid fa-circle-info" class="info-transform"
                        />
                        <b-card-text class="mb-0 mr-1 d-inline-block">
                          Komisyon Şemasını Kaydet :
                        </b-card-text>
                        <b-form-checkbox
                            v-model="semaKaydet"
                            checked="false"
                            class="custom-control-primary d-inline-block"
                            name="check-button"
                            switch
                        >
                                    <span class="switch-icon-left">
                                      <feather-icon icon="CheckIcon"/>
                                  </span>
                          <span class="switch-icon-right">
                    <feather-icon icon="XIcon"/>
                  </span>
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                  <hr/>
                </b-col>
                <b-col lg="8" md="12" xs="12" cols="12">
                  <table class="table table-striped" :class="width < 730 ? 'table-responsive':''">
                    <thead>
                    <tr>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Sıra No
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Ad Soyad
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Görev
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Ünvan
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      />
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in evrakGet" :key="index">
                      <td scope="row">{{ item.siraNo }}</td>
                      <td>{{ item.uye.adSoyad }}</td>
                      <td>{{ item.gorev }}</td>
                      <td>{{ item.unvan }}</td>
                      <td>
                        <b-button
                            variant="gradient-danger"
                            class="btn-icon rounded-circle mr-50"
                            size="sm"
                            @click="komisyonRemove(index,item.siraNo)"
                            :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                        >
                          <font-awesome-icon icon="fa-solid fa-trash-can"/>
                        </b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <h5 class="text-center" v-if="evrakGet.length === 0">Üye Eklenmedi</h5>
                </b-col>
                <b-col lg="4" md="12" xs="12" cols="12">
                  <b-list-group class="cursor-pointer">
                    <b-list-group-item @click="komisyonPanel(1)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 1 ? '#6096B4' : komisyonUyeleri['evrak1'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 1  /  Malzeme Talep</span>
                      <b-badge
                          v-if="currentEvrak === 1"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(2)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 2 ? '#6096B4' : komisyonUyeleri['evrak2'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 2  /  Satın Alma</span>
                      <b-badge
                          v-if="currentEvrak === 2"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(3)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 3 ? '#6096B4' : komisyonUyeleri['evrak3'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 3  /  Fiyat Araştırma</span>
                      <b-badge
                          v-if="currentEvrak === 3"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(4)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 4 ? '#6096B4' : komisyonUyeleri['evrak4'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 4  /  Yaklaşık Maliyet Hesaplama Tablosu</span>
                      <b-badge
                          v-if="currentEvrak === 4"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(5)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 5 ? '#6096B4' : komisyonUyeleri['evrak5'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 5  /  İhale Onay</span>
                      <b-badge
                          v-if="currentEvrak === 5"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(6)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 6 ? '#6096B4' : komisyonUyeleri['evrak6'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 6  /  Birim Fiyat Teklif Mektubu</span>
                      <b-badge
                          v-if="currentEvrak === 6"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(7)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 7 ? '#6096B4' : komisyonUyeleri['evrak7'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 7  /  Teklif Cetveli</span>
                      <b-badge
                          v-if="currentEvrak === 7"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(8)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 8 ? '#6096B4' : komisyonUyeleri['evrak8'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 8  /  Yaklaşık Maliyet Hesaplama Cetveli</span>
                      <b-badge
                          v-if="currentEvrak === 8"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(9)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 9 ? '#6096B4' : komisyonUyeleri['evrak9'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 9  /  İhaleye Katılanları Belirleme</span>
                      <b-badge
                          v-if="currentEvrak === 9"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(10)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 10 ? '#6096B4' : komisyonUyeleri['evrak10'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 10  /  Karar Tutanağı</span>
                      <b-badge
                          v-if="currentEvrak === 10"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item @click="komisyonPanel(11)"
                                       class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                       :style="{backgroundColor:  currentEvrak === 11 ? '#6096B4' : komisyonUyeleri['evrak11'].length === 0 ? '#F48484':'#62B6B7'}"
                    >
                      <span>Evrak No: 11  /  Teslim Alma Tutanağı</span>
                      <b-badge
                          v-if="currentEvrak === 11"
                          variant="primary"
                          class="badge-round"
                      >
                        ✔
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
              title="Kalem Bilgileri"
              icon="feather icon-edit-2"
              :before-change="wizardThirdRules"
          >
            <validation-observer
                ref="thirdStep"
                tag="form"
            >
              <b-row>
                <b-col
                    cols="12"
                    class="mb-2"
                >
                  <h5 class="mb-0">
                    '{{ ihaleBaslik }}' İhalesinin Kalemleri
                  </h5>
                  <small class="text-muted">Kalem Bilgileri Girilmelidir</small>
                </b-col>
                <b-col md="12">
                  <hr/>
                  <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                  >
                    <b-form
                        id="my-form"
                        ref="form"
                        :style="{height: trHeight}"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                    >
                      <b-row
                          v-for="(item, index) in items"
                          :key="item.id"
                          ref="row"
                      >
                        <b-col lg="3" md="3">
                          <b-form-group
                              label="Mal, Hizmet ya da İşin Cinsi"
                              label-for="item-name"
                          >
                            <b-form-input
                                id="item-name"
                                type="text"
                                autocomplete="off"
                                v-model="item.isim"
                                :state="items.length == 0 ?  errors.length > 0 ? false:null : null"
                            />
                            <small class="text-danger">{{ errors[0] ? 'Zorunlu alan' : '' }}</small>
                          </b-form-group>
                        </b-col>
                        <b-col lg="2" md="2">
                          <b-form-group
                              label="Miktarı"
                              label-for="quantity"
                          >
                            <b-form-input
                                id="quantity"
                                type="number"
                                autocomplete="off"
                                v-model="item.miktar"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col lg="2" md="2">
                          <b-form-group
                              label="Birimi"
                              label-for="cost"
                          >
                            <b-form-select v-model="item.birim" :options="birimOptions"/>
                          </b-form-group>
                        </b-col>
                        <b-col lg="4" md="4">
                          <b-form-group
                              label="Asgari Özellikler"
                              label-for="item-name"
                          >
                            <b-form-input
                                id="item-name"
                                type="text"
                                autocomplete="off"
                                v-model="item.ozellikler"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                            lg="1"
                            md="1"
                            class="mb-50"
                        >
                          <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2 float-right"
                              @click="removeItem(index)"
                          >
                            <feather-icon
                                icon="XIcon"
                                class=""
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-provider>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeateAgain"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Ekle</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
              title="İhale Onay"
              :before-change="wizardFourthRules"
          >
            <validation-observer
                ref="fourthStep"
                tag="form"
            >
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h3 class="text-center">İHALE ONAY BELGESİ</h3>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="my-50">İhaleyi Yapan Kurumun Adı</h5>
                </b-col>
                <b-col cols="9" class="border">
                  <h5 class="my-50">Öğretmenevi ve Akşam Sanat Okulu Müdürlüğ/SİVAS</h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="my-50">Belge Tarih ve Sayısı</h5>
                </b-col>
                <b-col cols="9" class="border">
                  <h5 class="my-50">{{ belgeTarihSayi }}</h5>
                </b-col>
                <b-col cols="12" class="border">
                  <h3 class="text-center m-1">ÖĞRETMENEVİ ve AKŞAM SANAT OKULU MÜDÜRLÜĞÜNE / SİVAS</h3>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="my-50">İşin Tanımı</h5>
                </b-col>
                <b-col cols="10" class="border">
                  <h5 class="my-50">{{ isTanim }}</h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="my-50">İşin Niteliği</h5>
                </b-col>
                <b-col cols="10" class="border">
                  <h5 class="my-50">{{ isNitelik }}</h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="my-50">İşin Miktarı</h5>
                </b-col>
                <b-col cols="10" class="border">
                  <h5 class="my-50">{{ isMiktar }}</h5>
                </b-col>
                <b-col cols="12" class="border">
                  <h4 class="m-4"></h4>
                </b-col>
                <!--                <b-col cols="5" class="border">-->
                <!--                  <h5 class="my-50">Yaklaşık Maliyet</h5>-->
                <!--                </b-col>-->
                <!--                <b-col cols="7" class="border">-->
                <!--                  <h5 class="my-50">-->
                <!--                    {{-->
                <!--                      Number(odenekTutari)-->
                <!--                          .toFixed(2)-->
                <!--                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')-->
                <!--                    }}-->
                <!--                    <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>-->
                <!--                  </h5>-->
                <!--                </b-col>-->
                <!--                <b-col cols="5" class="border">-->
                <!--                  <h5 class="my-50">Kullanılabilir Ödenek Tutarı</h5>-->
                <!--                </b-col>-->
                <!--                <b-col cols="7" class="border">-->
                <!--                  <h5 class="my-50"></h5>-->
                <!--                </b-col>-->
                <b-col cols="5" class="border">
                  <h5 class="my-50">Yatırım Proje Numarası (varsa)</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ projeNo }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">Bütçe Tertibi (varsa)</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ butceTertibi }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">Avans Verilecekse Şartları</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ avansSartlari }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">İhale Usulü</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ ihaleUsulu }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">İlanın Şekli ve Adedi</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ ilanSekliAdeti }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">Ön Yeterlik/İhale Dokümanı Satış Bedeli</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ dokumanSatisBedeli }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">Fiyat Farkı Ödenecekse Dayanağı </h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ fiyatFarkDayanagi }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">Bakanlar Kurulu Kararı</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ bakanlarKuruluKarari }}</h5>
                </b-col>
                <b-col cols="5" class="border">
                  <h5 class="my-50">İhale İle İlgili Diğer Açıklamalar</h5>
                </b-col>
                <b-col cols="7" class="border">
                  <h5 class="my-50">{{ digerAciklamalar }}</h5>
                </b-col>
                <b-col cols="12" class="border">
                  <h2 class="m-1 text-center">Onay</h2>
                </b-col>
                <b-col cols="6" class="border">
                  <h6 class="my-50 text-center">Yukarıda belirtilen malzemelerin alınması için İhaleye çıkılması
                    hususunu Olurlarınıza arz ederim.</h6>
                  <h6 class="my-1 text-center">{{ belgeTarih }}</h6>
                  <h5 class="m-0 text-center">{{ ihaleOnayUyeleri(1).adSoyad }}</h5>
                  <h6 class="text-center">{{ ihaleOnayUyeleri(1).unvan }}</h6>
                </b-col>
                <b-col cols="6" class="border" v-if="yetkili">
                  <h5 class="mt-1 mb-0 text-center">Uygundur.</h5>
                  <h6 class="m-0 text-center">{{ belgeTarih }}</h6>
                  <h5 class="mt-1 mb-0 text-center">{{ yetkili.adSoyad }}</h5>
                  <h6 class="m-0 text-center">{{ yetkili.unvan }}</h6>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-container>
    <b-modal
        id="komisyon-kayit"
        title="Üye Kayıt"
        centered
        size="lg"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        @ok="komisyonKayit"
    >
      <b-row class="m-1">
        <b-col cols="6">
          <b-form-group
              label="Adı Soyadı"
              label-for="vi-first-name"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-user"/>
              </b-input-group-prepend>
              <b-form-input
                  id="vi-first-name"
                  autocomplete="off"
                  v-model="uyeSchema.adSoyad"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Ünvan"
              label-for="unvan"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-feather"/>
              </b-input-group-prepend>
              <b-form-input
                  id="vi-first-name"
                  autocomplete="off"
                  v-model="uyeSchema.unvan"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BForm,
  BTab,
  BTabs,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormTextarea,
  BCard,
  BCardText,
  BContainer,
  BFormSelect,
  BFormInvalidFeedback,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, BInputGroupPrepend, BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from './wizard/validations.js'
import { heightTransition } from '@core/mixins/ui/transition'
import axios from 'axios'
import _ from 'lodash'
import flatpickr from 'flatpickr'
import Cleave from 'vue-cleave-component'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BForm,
    Cleave,
    vSelect,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  mixins: [heightTransition],
  data() {
    return {
      komisyonUyeTur: '',
      uyeSchema: {
        adSoyad: '',
        unvan: '',
      },
      delimiter: {
        delimiter: '/',
        blocks: [4, 2],
        uppercase: true,
      },
      semaKaydet: false,
      currentEvrak: 1,
      komisyonUyeleri: {
        evrak1: [],
        evrak2: [],
        evrak3: [],
        evrak4: [],
        evrak5: [],
        evrak6: [],
        evrak7: [],
        evrak8: [],
        evrak9: [],
        evrak10: [],
        evrak11: [],
      },
      uyeEkle: {
        siraNo: 1,
        uye: '',
        gorev: '',
      },
      ihaleBaslik: '',
      belgeTarihSayi: '',
      belgeTarih: '',
      isTanim: '',
      isNitelik: '',
      isMiktar: '',
      odenekTutari: '',
      projeNo: '',
      ilgiliBirim: '',
      butceTertibi: '',
      avansSartlari: '',
      ihaleUsulu: '4734 sayılı Kanun’un 22.maddesi (d) bendi',
      ilanSekliAdeti: '',
      dokumanSatisBedeli: '',
      fiyatFarkDayanagi: '',
      bakanlarKuruluKarari: '',
      digerAciklamalar: '',
      items: [{
        id: 1,
        isim: '',
        miktar: 1,
        birim: 'Adet',
        kdv: '',
        ozellikler: '',
        birimFiyat: '',
        toplamFiyat: '',
      }],
      nextTodoId: 2,
      required,
      yetkili: null,
      eklenenUyeler: [],
      birimOptions: ['Adet', 'Koli', 'Paket', 'Kutu', 'Kg', 'Gram', 'Litre', 'Ton', 'Net Ton', 'Gross Ton', 'Yıl', 'Saat', 'Dakika', 'Saniye', 'mm', 'cm', 'Hücre Adet', 'Cift', 'Set', 'Düzine', 'Brüt Kalori'],
      gorevOpt: ['Başkan', 'Başkan Yrd.', 'Üye'],
    }
  },
  computed: {
    evrakGet() {
      return _.orderBy(this.komisyonUyeleri[`evrak${this.currentEvrak}`], 'siraNo', 'asc')
    },
    getKomisyonUyeleri() {
      return Object.values(store.getters.KOMISYON_UYELER_GET)
    },
  },
  methods: {
    komisyonKayit() {
      store.dispatch('komisyonUyeKaydet', {
        adSoyad: this.uyeSchema.adSoyad,
        unvan: this.uyeSchema.unvan,
      })
          .then((res, position = 'bottom-right') => {
            store.dispatch('komisyonUyeleri')
            this.komisyonUyeTur === 'yetkili' ? this.yetkili = res.uyeData : this.uyeEkle.uye = res.uyeData
            this.komisyonUyeTur = ''
            this.uyeSchema.adSoyad = ''
            this.uyeSchema.unvan = ''
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    ihaleOnayUyeleri(siraNo) {
      return this.komisyonUyeleri.evrak5.find(x => x.siraNo == siraNo)?.uye || ''
    },
    komisyonRemove(index, siraNo) {
      const content = `evrak${this.currentEvrak}`
      const uyeler = this.komisyonUyeleri[content]
      const silinecekIndex = uyeler.findIndex(x => x.siraNo === siraNo)
      if (silinecekIndex !== -1) {
        uyeler.splice(silinecekIndex, 1)
      }
      const maxSiraNo = Math.max(...this.komisyonUyeleri[content].map(x => x.siraNo), 0)
      this.uyeEkle.siraNo = maxSiraNo + 1
    },
    komisyonUyeEkle() {
      const content = `evrak${this.currentEvrak}`
      const eklenecekEvrak = this.komisyonUyeleri[content]
      let isEvrak1 = content == 'evrak1'
      if (isEvrak1 && eklenecekEvrak.length == 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Malzeme Talep Formunda, ihale yetkilisinin yanına sadece 1 kişi eklenebilir',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (this.uyeEkle.siraNo === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Sıra No Girilmelidir',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (this.uyeEkle.uye === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Üye Seçilmelidir',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
        return false
      }
      const siraControl = eklenecekEvrak.find(x => x.siraNo === this.uyeEkle.siraNo)
      const uyeControl = eklenecekEvrak.find(x => x.uye.uyeID === this.uyeEkle.uye.uyeID)
      if (!siraControl) {
        if (!uyeControl) {
          eklenecekEvrak.push({
            siraNo: this.uyeEkle.siraNo,
            uye: this.uyeEkle.uye,
            gorev: this.uyeEkle.gorev,
            unvan: this.uyeEkle.uye.unvan,
          })
          this.uyeEkle.siraNo++
          this.uyeEkle.uye = ''
          this.uyeEkle.gorev = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata',
              text: 'Aynı Üye 2 Kez Eklenemez',
              icon: 'ThumbsUpIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Sıra No mevcut',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
      }
      return false
    },
    komisyonPanel(evrakNo) {
      this.currentEvrak = evrakNo
      const content = `evrak${evrakNo}`
      const eklenecekEvrak = this.komisyonUyeleri[content]
      const maxSiraNo = Math.max(...eklenecekEvrak.map(x => x.siraNo), 0)
      this.uyeEkle.siraNo = maxSiraNo + 1
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId++,
        isim: null,
        miktar: 1,
        birim: 'Adet',
        kdv: null,
        birimFiyat: null,
        toplamFiyat: null,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', 'ihaleKaydet')
      fd.append('saveData[semaKaydet]', this.semaKaydet === true ? 1 : 0)
      fd.append('saveData[ihaleBilgileri][ihaleYetkilisi]', this.yetkili.uyeID)
      fd.append('saveData[ihaleBilgileri][ihaleBaslik]', this.ihaleBaslik)
      fd.append('saveData[ihaleBilgileri][belgeTarihSayi]', this.belgeTarihSayi)
      fd.append('saveData[ihaleBilgileri][belgeTarih]', this.belgeTarih)
      fd.append('saveData[ihaleBilgileri][ilgiliBirim]', this.ilgiliBirim)
      fd.append('saveData[ihaleDetayi][isTanim]', this.isTanim)
      fd.append('saveData[ihaleDetayi][isNitelik]', this.isNitelik)
      fd.append('saveData[ihaleDetayi][isMiktar]', this.isMiktar)
      fd.append('saveData[ihaleDetayi][odenekTutari]', this.odenekTutari)
      fd.append('saveData[ihaleDetayi][projeNo]', this.projeNo)
      fd.append('saveData[ihaleDetayi][butceTertibi]', this.butceTertibi)
      fd.append('saveData[ihaleDetayi][avansSartlari]', this.avansSartlari)
      fd.append('saveData[ihaleDetayi][ihaleUsulu]', this.ihaleUsulu)
      fd.append('saveData[ihaleDetayi][ilanSekliAdeti]', this.ilanSekliAdeti)
      fd.append('saveData[ihaleDetayi][dokumanSatisBedeli]', this.dokumanSatisBedeli)
      fd.append('saveData[ihaleDetayi][fiyatFarkDayanagi]', this.fiyatFarkDayanagi)
      fd.append('saveData[ihaleDetayi][bakanlarKuruluKarari]', this.bakanlarKuruluKarari)
      fd.append('saveData[ihaleDetayi][digerAciklamalar]', this.digerAciklamalar)
      this.items.forEach((item, index) => {
        if (item.isim) {
          fd.append(`saveData[ihaleKalemleri][${index}][kalemAdi]`, item.isim)
          fd.append(`saveData[ihaleKalemleri][${index}][asgariOzellik]`, item.ozellikler)
          fd.append(`saveData[ihaleKalemleri][${index}][miktar]`, item.miktar)
          fd.append(`saveData[ihaleKalemleri][${index}][birimTuru]`, item.birim)
        }
      })
      Object.entries(this.komisyonUyeleri)
          .forEach((item, index) => {
            const evrakNo = item[0].includes(10) ? 10 : item[0].includes(11) ? 11 : item[0].slice(5, 6)
            item[1].forEach((uye, uyeIndex) => {
              fd.append(`saveData[komisyonUyeleri][${index}][liste][${uyeIndex}][uyeID]`, uye.uye.uyeID)
              fd.append(`saveData[komisyonUyeleri][${index}][liste][${uyeIndex}][sira]`, uye.siraNo)
              fd.append(`saveData[komisyonUyeleri][${index}][liste][${uyeIndex}][gorev]`, uye.gorev)
            })
            fd.append(`saveData[komisyonUyeleri][${index}][evrakNo]`, evrakNo)
          })
      return fd
    },
    formSubmitted() {
      axios.post(store.state.POST_URL, this.postSchema(), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              router.push({
                name: 'ihaleDetay',
                params: { id: res.data.ihaleDetay.detayID },
              })
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
              store.commit('setToken', res.data.userToken)
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'warning',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    wizardFirstRules() {
      return new Promise((resolve, reject) => {
        this.$refs.firstStep.validate()
            .then(success => {
              if (success) {
                this.komisyonPanel(1)
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
    wizardSecondRules() {
      const doluMu = Object.keys(this.komisyonUyeleri)
          .every(uye => this.komisyonUyeleri[uye].length > 0)
      if (doluMu) {
        return new Promise((resolve, reject) => {
          this.$refs.secondStep.validate()
              .then(success => {
                if (success) {
                  resolve(true)
                } else {
                  reject()
                }
              })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Her evrak için en az bir tane üye seçilmelidir',
            icon: 'ThumbsDownIcon',
            variant: 'warning',
          },
        })
      }
      return false
    },
    wizardThirdRules() {
      return new Promise((resolve, reject) => {
        this.$refs.thirdStep.validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
    wizardFourthRules() {
      return new Promise((resolve, reject) => {
        this.$refs.fourthStep.validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
  },
  beforeDestroy() {
    store.commit('KOMISYON_UYELER_ACTION', 'remove')
  },
  mounted() {
    this.initTrHeight()
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    store.dispatch('komisyonSema')
        .then(res => {
          const data = { ...res }
          delete data.userToken
          Object.values(data)
              .forEach(item => {
                const { evrakNo } = item
                const obj = {
                  siraNo: item.sira,
                  uye: item,
                  gorev: item.gorev,
                  unvan: item.unvan,
                }
                if (evrakNo >= 1 && evrakNo <= 11) {
                  this.komisyonUyeleri[`evrak${evrakNo}`].push(obj)
                }
              })
        })
    store.dispatch('komisyonUyeleri')
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>
<style lang="scss">
.info-transform {
  transform: translate(-5px, 4px);
}

.komisyon-add-btn {
  transform: translate(0px, 22px);
}

.ihale-wizard-komisyon-card .table th, .table td {
  padding: 0.3rem 2rem;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.evrak-list-border {
  border: none;
  border-top: none;
  border-bottom: 0.1px solid #f7f7f7;
}

@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
